<template>
  <v-container class="checkout">
  <div>

  </div>
    <header>
      <h1>Checkout</h1>
    </header>
    <v-form class="d-flex flex-row justify-center">
      <articel class="fields flex-grow-1 pr-8">
        <header>Billing Details</header>

        <main>
          <div class="field-row">
            <v-text-field outlined label="First Name" />
            <v-text-field outlined label="Last Name" />
          </div>
          <div class="field-row field-row--card">
                <div class="card--row card--number">
                    <label>Card Number</label>
                    <div id="card-number"></div>
                </div>
                <div class="card--row card--expiry">
                    <label>Card Expiry</label>
                    <div id="card-expiry"></div>
                </div>
                <div  class="card--row card--cvc">
                    <label>Card CVC</label>
                    <div id="card-cvc"></div>
                </div>
                <div id="card-error"></div>
          </div>

          <v-text-field outlined label="Company Name" />
          <v-text-field outlined label="Country" />
          <v-text-field outlined label="Street Address" />
          <v-text-field outlined label="Apartment, suite, unit, etc." />
          <v-text-field outlined label="City" />
          <v-text-field outlined label="County" />
          <v-text-field outlined label="Zip Code" />

          <div class="field-row">
            <v-text-field outlined label="Phone" />
            <v-text-field outlined label="Email" />
          </div>
        </main>
      </articel>
      <article class="orders">
        <header>Your Order</header>

        <main>
          <v-row class="header" justify="center" dense>
            <v-col>Purchase</v-col>
            <v-col>Total</v-col>
          </v-row>

          <v-row class="item" justify="center" dense>
            <v-col>
              Interactive Package 2 PODs Chosen
            </v-col>

            <v-col> $1800.00 </v-col>
          </v-row>

          <v-row class="total total--subtotal" justify="center" dense>
            <v-col>Subtotal</v-col>
            <v-col> $1800.00 </v-col>
          </v-row>
          <v-row class="total total--final" justify="center" dense>
            <v-col>Total</v-col>
            <v-col> $1800.00 </v-col>
          </v-row>
        </main>

        <footer>
          <v-btn large block color="black white--text">
            Place Order
          </v-btn>
        </footer>
      </article>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
    };
  },
  computed: {
    stripeElements () {
      return this.$stripe.elements();
    },
  },
  mounted () {
    // Style Object documentation here: https://stripe.com/docs/js/appendix/style
    const style = {
      base: {
        color: 'white',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };
    this.cardNumber = this.stripeElements.create('cardNumber', { style });
    this.cardNumber.mount('#card-number');
    this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = this.stripeElements.create('cardCvc', { style });
    this.cardCvc.mount('#card-cvc');
  },
  beforeDestroy () {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  methods: {
    async createToken () {
      const { token, error } = await this.$stripe.createToken(this.cardNumber);
      if (error) {
        // handle error here
        document.getElementById('card-error').innerHTML = error.message;
        return;
      }
      console.log(token);
      // handle the token
      // send it to your server
    },
  }
};
</script>

<style lang="scss" scoped>
.checkout {
  width: 90%;
  margin: auto;
}

.checkout header {
  padding: 1rem;
  text-align: center;

  text-transform: uppercase;

  font-size: 30px;
  font-weight: bolder;
}

.field-row {
  display: flex;
  flex-flow: row nowrap;

  justify-content: space-around;
  align-items: center;

  & > *:first-child {
    margin-right: 1rem;
  }
}

.orders main {
  padding: 1.5rem;
  padding-top: 0;
}

.orders .header,
.orders main .total > *:first-child {
  // background-color: #ddd;

  // border: solid 2px #333;

  font-size: 20px;
  font-weight: 800;
}

.orders .header {
  padding: 1rem;
}

.orders main .total.total--subtotal > *:first-child {
  border-bottom: unset;
}

.orders main .total.total--final > *:first-child {
  border-top: unset;
}



#card-error {
  color: red;
}
.field-row{
      &.field-row--card{
        justify-content: flex-start;
        padding-bottom: 35px;
      }

    .card--row{
        display: flex;
        min-width: 300px;
        min-height: 56px;
        border: 1px solid rgba(255, 255, 255, 0.24);
        padding: 0 12px;
        align-items: center;
        border-radius: 4px;
        justify-content: flex-start;
        label{
          width:170px;
        }
        div{
          width:100%;
        }
        &.card--number{
          max-width:400px;
          width:100%;
        }
        &.card--expiry{
          max-width:100px;
          width:100%;
          margin-right:1em;
        }
    }
}

</style>
